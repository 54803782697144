

const ProductDescription = ({ description:__html }) => (
  <div
    style={{
      overflow: 'auto',
      maxHeight: '180px',
      padding: '10px'
    }}
    className="quickViewDescription"
    dangerouslySetInnerHTML={{ __html }}
  ></div>
)

export default ProductDescription
