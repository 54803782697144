import React from 'react'
import type { Property } from 'csstype'
import styles from './ProductAvailability.module.scss'

type ProductAvailabilityProps = {
  display?: Property.Display
  pageType?: boolean
  comingFromATCModal?: boolean
  availability?: React.ReactNode
  classes?: {
    availabilityTitle?: string
  }
}
const ProductAvailability = ({
  display,
  pageType,
  comingFromATCModal,
  availability,
  classes = {
    availabilityTitle: '',
  },
}: ProductAvailabilityProps) => (
  <div className={`quickView-itemSelection ${pageType ? styles.availabilityCart : ''}`} style={display ? { display } : {}}>
    <p className={`productView-info-name ${pageType ? styles.availabilityKey : ''} ${classes?.availabilityTitle || ''}`} style={comingFromATCModal ? { color: '#828282', width: 'auto', paddingRight: '0' } : { marginRight: 0 }}>Availability: </p>
    <p
      className="productView-info-value availability-container"
      style={{
        textTransform: 'capitalize', width: '67%', marginBottom: 0, fontSize: '14px',
      }}
    >
      {availability}
    </p>
  </div>
)

export default ProductAvailability
