import React from 'react'

import clsx from 'clsx'
import { LoadingGraphic } from '@/components/Generic/Icon/'
import { handleQTYInput } from '@/helpers/common'
import { regularAddToCartFlow } from '@/helpers/checkoutHelpers'
import { FEATURE_TOGGLES } from '@/services/Configuration'
import styles from './AddToCart.module.scss'
import { error } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'

const BOPUS_FEATURE_TOGGLE = FEATURE_TOGGLES?.checkout_isBopusEnabled

class AddToCart extends React.Component {
  constructor (props) {
    super(props)
    this.quantityRef = React.createRef()
    this.state = {
      isLoading: false,
      findInStore: false,
      inputValue: 1,
      inventoryQty: null
    }
  }
  async componentDidMount() {
    await this.getInventory(this.props.sku)
  }
  getInventory = async () => {
    try {
      const [
        { getProductInventoryFromBigCommerceViaGraphQL }
      ] = await allPromisesWithRetries(() => [
        import('@/helpers/graphql')
      ])
      const {
        inventory: inventoryQty,
        success,
      } = await getProductInventoryFromBigCommerceViaGraphQL({
        productId: this.props.id,
        variantId: this.props.variantId,
      })
      if(success) {
        this.setState({inventoryQty})
      }
    } catch (e) {
      error(e)
    }
  }
  get loading () {
    return this.state.isLoading
  }
  set loading(isLoading) {
    this.setState((prevState) => ({ ...prevState, isLoading }))
  }
  addToCart = async event => { // Quick look add to cart
    event.preventDefault()
    event.stopPropagation()
    if(this.state.inventoryQty !== null && this.state.inventoryQty !== 0 && this.state.inputValue > this.state.inventoryQty) {
      return null
    }
    this.loading = true
    const quantity = parseInt(this.quantityRef.current.value)
    const productId = this.props?.id
    const variantId = this.props?.variantId
    try {
      // Need to pass variantId
      const { cartAddition} = await regularAddToCartFlow({ productId, quantity, variantId })
      this.props?.onCartAdded?.(cartAddition)
      this.props?.fetchCart()
    } catch (e){
      console.error('Regular add to cart did not work', e)
    }
  }
  launchBopus = event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onBopusLaunch(parseInt(this.quantityRef.current.value))
  }

  availableStore = () => {
    const is_available = this.props.additional_info[this.props.sku]?.is_available_at_store
    return !!is_available && is_available !== '0'
  }

  handleInput = (e) => {
    const result = handleQTYInput(e)
    if (result !== null) {
      return this.setState({inputValue: result})
    }
    return null
  }

  render () {
    const isQtyFieldEmpty = !Number(this.state.inputValue);
    return (
      <div className="add-to-cart-div">
        <div className="atc-qty-cta ">
          <div className="form-field form-field--increments">
            <label className="form-label form-label--alternate" htmlFor="qty[]">Qty:</label>
            <div className="form-increment" data-quantity-change>
              <button className="button button--icon" data-action="dec">
                <span className="is-srOnly">
                  Decrease Quantity:
                </span>
                <i className="icon" aria-hidden="true">
                  <svg>
                    <use xlinkHref="#icon-keyboard-arrow-down" />
                  </svg>
                </i>
              </button>
              <div style={{position: 'relative'}}>
                <input
                    style={{borderColor: `${this.state.inventoryQty !== null && this.state.inventoryQty !== 0 && this.state.inputValue > this.state.inventoryQty ? '#CA3B4C' : ''}`}}
                    ref={this.quantityRef}
                    className="form-input form-input--incrementTotal"
                    id="qty[]"
                    name="qty[]"
                    type="tel"
                    value={this.state.inputValue}
                    onChange={this.handleInput}
                    data-quantity-min={0}
                    data-quantity-max={0}
                    min={1}
                    pattern="[0-9]*"
                    aria-live="polite"
                />
                {isQtyFieldEmpty && (
                 <p className={styles.inventoryQtyErrorMessage}>
                   Value is required
                 </p>
                )}
                {this.state.inventoryQty !== null && this.state.inventoryQty !== 0 && this.state.inputValue > this.state.inventoryQty && (
                    <p className={styles.inventoryQtyErrorMessage}>
                      Maximum of
                      {' '}
                      {this.state.inventoryQty}
                    </p>
                )}
              </div>
              <button className="button button--icon" data-action="inc">
                <span className="is-srOnly">Increase Quantity:</span>
                <i className="icon" aria-hidden="true">
                  <svg>
                    <use xlinkHref="#icon-keyboard-arrow-up" />
                  </svg>
                </i>
              </button>
            </div>
          </div>
          <div className="atc-store">
            <div className="form-action">
              {this.loading ? (
                <img
                  className="button button--primary"
                  src={LoadingGraphic}
                  alt="loading-state"
                  style={{
                    width: '165px',
                    height: '40px',
                    padding: 0,
                    margin: '6px 0 6px'
                  }}
                />
              ) : (
                <input
                  id="form-action-addToCart"
                  data-wait-message="Adding to cart…"
                  className={clsx(isQtyFieldEmpty && styles.disabledButton, "button", "button--primary", "ql-addtocart")}
                  type="submit"
                  value="Add to Cart"
                  onClick={this.addToCart}
                  data-testid="pdp-add-to-cart"
                  disabled={isQtyFieldEmpty}
                />
                )}
            </div>
            {this.availableStore() && BOPUS_FEATURE_TOGGLE && <a className="findStore ql-findinstore" onClick={this.launchBopus} data-testid="pdp-find-in-store">Find in Store</a>}
          </div>
        </div>
        <div className="alertBox productAttributes-message" style={{display: 'none'}}>
          <div className="alertBox-column alertBox-icon">
            <icon glyph="ic-success" className="icon" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
              </svg>
            </icon>
          </div>
          <p className="alertBox-column alertBox-message" />
        </div>
      </div>
    )
  }
}

export default AddToCart
