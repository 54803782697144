import { CSSProperties, useRef } from 'react'
import { useRouter } from 'next/router'
import get from 'lodash/get'
import ProductHelpers, { IMAGE_FALLBACK } from '@/helpers/Product'
import type { Product, ProductImageSize } from '@/types/Product'

import { useOnScreen } from '@/hooks/useOnScreen'
import { useIsVisible } from '@/hooks/useIsVisible'

interface ProductImageProps {
  className?: string,
  size: ProductImageSize,
  index?: number,
  style?: CSSProperties,
  product: Product,
}

function ProductImage({
  className = '', size, index = 0, style, product,
}: ProductImageProps) {
  const router = useRouter()
  const imageRef = useRef(null)
  const onScreen = useOnScreen(imageRef)
  const visible = useIsVisible(imageRef)

  const title = ProductHelpers.name({ source: product })
  const color = String(get(router, 'query.color'))
  const isVisible = onScreen && visible

  let src = IMAGE_FALLBACK
  if (isVisible) {
    src = ProductHelpers.image({
      product, index, size, color,
    })
  }

  return (
    <img
      ref={imageRef}
      style={style}
      className={className}
      src={src}
      data-src={src}
      alt={title}
      title={title}
    />
  )
}

export default ProductImage
