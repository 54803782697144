/* eslint-disable @typescript-eslint/prefer-regexp-exec */
const browsersNamePattern = /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
export const getBrowserNameAndVersion = () => {
  try {
    if (typeof window !== 'undefined' && typeof window.navigator === 'object') {
      const browserMatches = window.navigator.userAgent.match(browsersNamePattern) || []
      if (/trident/i.test(browserMatches[1])) {
        const tem = /\brv[ :]+(\d+)/g.exec(window.navigator.userAgent) || []
        return { name: 'IE', version: (tem[1] || 0) }
      }
      if (browserMatches[1] === 'Chrome') {
        const operaVersionMatch = window.navigator.userAgent.match(/\bOPR|Edge\/(\d+)/)
        if (operaVersionMatch !== null) {
          return {
            name: 'Opera',
            version: operaVersionMatch[1],
          }
        }
      }
      const nameAndVersionMatch = browserMatches[2] ? [browserMatches[1], browserMatches[2]] : [navigator.appName, navigator.appVersion, '-?']
      const versionRegexMatch = window.navigator.userAgent.match(/version\/(\d+)/i)
      if (versionRegexMatch !== null) {
        nameAndVersionMatch.splice(1, 1, versionRegexMatch[1])
      }
      const browsername = nameAndVersionMatch[0]
      const version = parseFloat(nameAndVersionMatch[1])
      if (!Number.isNaN(version)) {
        return {
          name: browsername,
          version,
        }
      }
    }
  } catch (error) {
    // Failed to get browser version
  }
  return null
}

export default getBrowserNameAndVersion
