import * as BlackCheckImport from './BlackCheck.svg'
import * as BlackLeftArrowImport from './BlackLeftArrow.svg'
import * as BlackRightArrowImport from './BlackRightArrow.svg'
import * as LoadingGraphicImport from './LoadingGraphic.svg'
import * as MinusImport from './Minus.svg'
import * as PlusImport from './Plus.svg'
import * as ShippingTruckImport from './truck.svg'

export const BlackCheck = BlackCheckImport
export const BlackLeftArrow = BlackLeftArrowImport
export const BlackRightArrow = BlackRightArrowImport
export const LoadingGraphic = LoadingGraphicImport
export const Minus = MinusImport
export const Plus = PlusImport
export const ShippingTruck = ShippingTruckImport
