import { useCallback, useEffect, useState } from 'react'

type UseIsVisibleHook<T = HTMLDivElement> = (previewElement: React.RefObject<T>) => boolean
export const useIsVisible: UseIsVisibleHook = (previewElement) => {
  const [isVisible, setIsVisible] = useState(false)
  const isElementVisible = useCallback(({
    window,
    document,
  }: {
    window: Window,
    document: Document,
  }) => {
    if (!previewElement.current || !window || !document || !document?.documentElement) {
      return false
    }
    const { innerHeight, innerWidth } = window
    const { clientHeight, clientWidth } = document.documentElement

    const {
      top, left, bottom, right,
    } = previewElement.current.getBoundingClientRect()
    const height = innerHeight || clientHeight
    const width = innerWidth || clientWidth
    const verticallyInside = (spot: number) => spot >= 0 && spot <= height
    const horizontallyInside = (spot: number) => spot >= 0 && spot <= width
    const topInside = verticallyInside(top)
    const bottomInside = verticallyInside(bottom)
    const leftInside = horizontallyInside(left)
    const rightInside = horizontallyInside(right)
    return (
      (topInside && leftInside)
      || (topInside && rightInside)
      || (bottomInside && leftInside)
      || (bottomInside && rightInside)
    )
  }, [previewElement])

  const startLoadIfVisible = useCallback(({ window, document }) => {
    if (!isVisible && isElementVisible({ window, document })) {
      setIsVisible(true)
    }
  }, [isElementVisible, isVisible])

  useEffect(() => {
    const loadBasedOnPosition = () => startLoadIfVisible({ window, document })
    loadBasedOnPosition()
    if (!isVisible) {
      window.addEventListener('scroll', loadBasedOnPosition)
    }
    return () => window.removeEventListener('scroll', loadBasedOnPosition)
  }, [startLoadIfVisible, isVisible])

  return isVisible
}

export default useIsVisible
