import React, { useEffect, useState } from 'react'
import { getBrowserNameAndVersion } from '@/helpers/getBrowserNameAndVersion'

const shouldRender = () => {
  const browser = getBrowserNameAndVersion()
  const shouldSendTrue = (
    !browser
    || (
      /safari/ig.test(browser.name)
      && typeof browser.version === 'number'
      && browser.version < 12
    )
  )
  if (typeof IntersectionObserver === 'undefined') {
    return true
  }
  return shouldSendTrue
}

export function useOnScreen(ref: React.RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(shouldRender())

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isIntersecting && ref.current) {
      try {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => setIntersecting((
            (visible) => visible || entry.isIntersecting)))
        })
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
      } catch (err) {
        console.error(`useOnScreen failed: ${err}`)
        setIntersecting(true)
      }
    }
  }, [ref, isIntersecting])

  return isIntersecting
}

export default useOnScreen
