type PriceLike = {
  sale_price?: number;
  price?: number;
}
type AnyOtherKeys = { [key: string]: unknown }
type WithVariants<T> = T & { variants?: (T & AnyOtherKeys)[] } & AnyOtherKeys

const salePriced = <T extends PriceLike>(
  { price: p, sale_price: s }: T,
): boolean => !!s && (s < Number(p))

const isOnSale = <T extends WithVariants<PriceLike>>(item: T): boolean => (item.variants?.length
  ? item.variants.some(salePriced)
  : salePriced(item))

export default isOnSale
