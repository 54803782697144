
import React from 'react'
import Link from 'next/link'

const INHERITABLE_PROPS = [
  'style',
  'className',
  'onClick'
]

const ProductLink = props => {
  const { custom_url, children } = props
  const url = custom_url?.url || '#'
  const parts = url.split('-')
  const sku = parts[parts.length - 1]
  const slug = parts.slice(0, -1).join('-')
  const inheritedProps = INHERITABLE_PROPS.reduce((hash, prop) => ({ ...hash, [prop]: props[prop] }), {})
  return (
    url === '#'
      ? (
        <a href={url} {...inheritedProps}>
          {children}
        </a>
      )
      : (
        <Link href={{ pathname: '/product', query: { sku, slug } }} as={url} prefetch={false}>
          <a href={url} {...inheritedProps}>
            {children}
          </a>
        </Link>
      )
  )
}

export default ProductLink
