import isOnSale from './isOnSale'

const dollars = (val) => {
  const str = `${val}`
  let [whole, decimal = ''] = str.split('.')
  while (decimal.length < 2) {
    decimal += '0'
  }
  return `$${whole}.${decimal}`
}
const pricingString = ({ min, max }) => {
  if (min === max) {
    return dollars(min)
  }
  return `${dollars(min)} - ${dollars(max)}`
}

const pricing = ({ prices, type }) => {
  const price = prices[type]
  const variantPrices = (prices.variants || []).map((v) => v[type])
  const allPrices = variantPrices.filter((value) => value)
  const min = Math.min(...allPrices)
  const max = Math.max(...allPrices)
  return { min, max, type }
}

const normalPrice = (prices) => pricingString(pricing({ prices, type: 'price' }))
const salePrice = (prices) => pricingString(pricing({ prices, type: 'sale_price' }))

const ProductPrice = ({ prices, is_on_sale, style }) => (
  <div className="productView-price" style={style || {}}>
    <div className="customPriceDiv">
      <div className={`price-section price-section--withoutTax ${isOnSale(prices) ? 'non-sale-price--withoutTax' : ''}`} style={{ marginRight: '3px' }}>
        <span data-product-non-sale-price-without-tax className={`price ${isOnSale(prices) ? 'price--non-sale nonSalePrice' : ''}`}>
          {normalPrice(prices)}
        </span>
      </div>
      <div className="price-section price-section--withoutTax">
        <span className="price-label" />
        <span data-product-price-without-tax className="price price--withoutTax actualSalePrice price-color" style={{ display: is_on_sale ? '' : 'none' }}>
          {salePrice(prices)}
        </span>
      </div>
    </div>
  </div>
)

export default ProductPrice
